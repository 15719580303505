.Home{
    min-height:calc(100vh - 315px);
    display:flex;
    justify-content: center;
    align-items: center;
}

.Icons{
    display:flex;
    align-items: center;
    flex-direction:column;
    justify-content: center;
    margin-bottom:20px;
    margin-top:30px;
}

.Icons div{
    padding:10px 20px;
}

.Icons div img{
    width:40px;
}

.ImageHome img{
    max-width:100%;
}

picture{
    width:100%;
}


@media screen and (min-width: 768px){
    .Icons div img{
        width:40px;
    }
    .ImageHome{
        display:block;
        margin-top:120px;
    }
    .Icons{
        flex-direction:row;
    }
   
}
