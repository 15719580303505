.FullImage{
    position:absolute;
    top:0;
    width:100%;
    height:100vh;
    background-color:rgba(255,255,255,0.7);
    z-index:100;
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction:column;
}

.FullImage img{
    max-width:85%;
    height:auto!important;
}

.FullImage img{
    max-width:85%;
    height:auto;
}

.Title{
    padding:15px;
    background-color:#000;
    color:#fff;
    margin-top:-10px;
}

.Close{
    margin-bottom:-10px;
    border-radius:12px;
    padding:10px;
    background-color:#333;
    color:#fff;
    cursor:pointer;
}

@media screen and (min-width:1000px){
    .FullImage img{
        max-width:none;
        height:auto;
        max-height:80%;
    } 
}