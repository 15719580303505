.Footer{
    background-color:#000;
    padding:10px;
}

.Footer > div{
    display:flex;
    justify-content: center;
}

.Footer > div a{
    padding:10px 20px;
}
