.Item {
    display:flex;
    margin:20px 0;
    background-color:#fff;
    padding:10px;
}

.InfoContent p{
    margin:0;
    padding:0;
}

.Info h4{
    margin-top:0;
    margin-bottom:10px;
}

.Image{
    flex: 0 0 100px;
    padding-right:20px;
}

.Image img{
    max-width:100%;
}

.Date{
    display:block;
    padding-top:10px;
}


@media screen and (min-width:1200px){
    .Info{
        flex: 0 0 60em;
    }
    .Image{
        flex: 0 0 8em;
        padding-right:15px;
    }

    .Date{
        padding-top:10px;
    }

    .InfoContent{
        display:flex;
    }
    

}