.NavItem{
    width:100%;
    background-color:#ccc;
    display:flex;
    justify-content: center;
    padding:15px 0;
}

.NavItem > a{
    margin:0 10px;
    font-size:18px;
    cursor:pointer;
    text-decoration: none;
    color:#000;
    
}

.NavItem > a.active{
  font-weight: bold  

}